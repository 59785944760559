<template>
  <div class="container">
    <el-form ref="form" :model="searchFormData" :inline="true">
      <el-form-item label="项目">
        <el-select
            @change="selectProject"
            v-model="searchFormData.project_id" placeholder="请选择项目"
            clearable
            filterable
            style="width:100%"
        >
          <el-option
              v-for="item in projectDataList"
              :key="item.project_id"
              :label="item.name"
              :value="item.project_id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="职位">
        <el-select
            v-model="searchFormData.job_id" placeholder="请选择职位"
            clearable
            filterable
            style="width:100%"
        >
          <el-option
              v-for="item in jobDataList"
              :key="item.job_id"
              :label="item.title"
              :value="item.job_id"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="类型">
        <el-select v-model="searchFormData.type"
                   clearable
                   filterable
                   placeholder="请选择类型">
          <el-option label="奖励" value="reward"></el-option>
          <el-option label="惩罚" value="punishment"></el-option>

        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
      </el-form-item>
    </el-form>

    <el-empty v-if="pageProps.records.length < 1" description="暂无数据"></el-empty>
    <section v-else>
      <div class="table">

        <!-- start -->
        <div class="pro_unit" v-for="(item, index) in pageProps.records" :key="index">
          <div class="pro_c">
            <b>{{ item.id }}</b>
          </div>

          <div class="pro_a">
            <img :src="item.project.image"/>
          </div>
          <div class="pro_b">
            <div>
              <b>{{ item.project.name }}</b>
              <b>{{ item.title }}</b>
              <span>
            </span>
            </div>
            <p>备注：{{ item.remark }}
            </p>
          </div>


          <div class="pro_c">
            <b>{{ item.type_name }}</b>
          </div>
          <div class="pro_c">
            <b>{{ item.directorSet.title }}</b>
          </div>
          <div class="pro_c">
            <p>职位</p>
            <b>{{ item.job.title }}</b>
          </div>
          <div class="pro_c">
            <p>员工</p>
            <b>{{ item.userApp.user_name }}</b>
          </div>
          <div class="pro_c">
            <p>金额</p>
            <b>{{ item.money_format }}</b>
          </div>

          <div class="pro_c">

            <b>{{ item.node_name }}-{{ item.status_name }}</b>
          </div>

          <div class="pro_e">
            <el-dropdown>
            <span class="el-dropdown-link">
              更多操作<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-edit" style="color:#4C71DB"
                                  @click.native="audit(item)"
                                  v-if="item.audit.status == 'untreated'"
                >审核
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <!-- end -->
      </div>
      <!--分页-->
      <div class="page_box">
        <el-pagination background layout="total, sizes, prev, pager, next, jumper"
                       :total="pageProps.total"
                       :page-sizes="[5, 10, 50, 100]"
                       :page-size="pageProps.pageSize"
                       :current-page="pageProps.pageNum"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"/>

      </div>

    </section>

    <audit-dialog ref="auditDialog" @refresh="fetchData"></audit-dialog>
  </div>
</template>

<script>

import {v2Query} from "@/api/common";

import auditDialog from "@/views/reward/dialog/audit-dialog.vue";

export default {
  components: {
    auditDialog,
  },

  data() {
    return {
      queryForm: {},
      searchLoading: false,
      projectDataList: [],
      jobDataList: [],
      pageProps: {
        records: [],
        total: 0,
        pageNum: 1,
        pageSize: 10,
      },
      searchFormData: {
        type: '',
        project_id: '',
        job_id: '',
        title: '',
        page: 0,
        limit: 0,
      },
    };
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    query() {
      return v2Query(this.queryForm);
    },
    resetQueryForm() {
      this.queryForm = {}
      return this;
    },
    rewardListQueryForm() {
      this.searchFormData.page = this.pageProps.pageNum;
      this.searchFormData.limit = this.pageProps.pageSize;

      this.queryForm.company_reward_list = {
        form: this.searchFormData,
      }
      return this;
    },
    queryProjectList: function () {
      this.queryForm.project_list_select = {
        form: {},
      };
      return this;
    },
    queryJobList: function () {
      this.queryForm.job_list = {
        form: {
          project_id: this.searchFormData.project_id,
        }
      }

      return this;
    },

    search() {
      this.fetchData();
    },
    selectProject(e) {
      let _this = this;
      _this.searchFormData.project_id = e;
      _this.searchFormData.job_id = ""

      _this.resetQueryForm()
          .queryJobList()
          .query()
          .then((response) => {
            if (response.code == 200) {
              _this.jobDataList = response.data.job_list;
              return true;
            }

            _this.$message.error(response.msg ? response.msg : "请求错误");
          });

    },
    fetchData() {
      var that = this;
      that.resetQueryForm()
          .rewardListQueryForm()
          .queryProjectList()
          .query()
          .then((response) => {
            var _data = response.data.company_reward_list;
            that.projectDataList = response.data.project_list_select;
            that.pageProps.records = _data.data;
            that.pageProps.total = _data.total;
          });
    },
    // 当前页变更
    handleCurrentChange(num) {
      this.pageProps.pageNum = num;
      this.fetchData();
    },

    // 每页显示页面数变更
    handleSizeChange(size) {
      this.pageProps.pageSize = size;
      this.fetchData();
    },

    audit(item) {
      this.$refs.auditDialog.init(item);
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  min-height: 500px;
}

.pro_unit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  border: 1px solid #cccccc;
  border-radius: 6px;
  margin-bottom: 24px;
  padding: 20px;
  height: 64px;
  font-size: 14px;
  color: #999;

  .pro_a {
    img {
      width: 64px;
      height: 64px;
      object-fit: cover;
      border-radius: 3px;
    }
  }

  .pro_b {
    max-width: 480px;
    min-width: 300px;

    height: 64px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    div {
      b {
        font-size: 20px;
        margin-right: 50px;
        color: #333;
      }

      span {
        color: #4c71db;
      }
    }

    p {
      white-space: nowrap;
      word-break: keep-all;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .pro_c {
    width: 130px;
    text-align: center;

    b {
      color: #333;
      line-height: 30px;
    }
  }

  .pro_d {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 120px;
    height: 80px;

    button {
      width: 100px;
      margin: 0 auto;
    }
  }
}

.page_box {
  display: flex;
  justify-content: center;
}
</style>
