<template>
  <el-dialog
      :visible.sync="visible"
      append-to-body
      :show-close="true"
      :center="true"
      :before-close="handleClose"
      title="奖惩申请"
      width="80%"
  >
    <div>
      <el-form ref="form" :model="formData" :rules="rules" label-width="120px">

        <el-form-item label="项目" prop="title">
          <span>{{ project.name || '无' }}</span>
        </el-form-item>
        <el-form-item label="职位" prop="title">
          <span>{{ job.title || '无' }}</span>
        </el-form-item>
        <el-form-item label="员工" prop="title">
          <span>{{ userApp.user_name || '无' }}</span>
        </el-form-item>
        <el-form-item label="员工" prop="title">
          <span>{{ userApp.user_name || '无' }}</span>
        </el-form-item>
        <el-form-item label="奖惩名称" prop="title">
          <span>{{ set.title || '无' }}</span>
        </el-form-item>
        <el-form-item label="奖惩类型" prop="title">
          <span>{{ set.type_name || '无' }}</span>
        </el-form-item>
        <el-form-item label="奖惩金额" prop="title">
          <span>{{ set.money_format || '无' }}</span>
        </el-form-item>

        <el-form-item label="备注">
          <el-input type="textarea" v-model="formData.remark"></el-input>
        </el-form-item>

      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit('pass')">通 过</el-button>
      <el-button type="danger" @click="submit('reject')">驳 回</el-button>
    </span>
  </el-dialog>
</template>
<script>

import {v2Query} from "@/api/common";

export default {
  data() {
    return {
      defaultData: {
        id: 0,
        node: 'company',
        remark: "",
        status: 'pass',
      },
      queryForm: {},
      project: {},
      job: {},
      set: {},
      userApp: {},
      reward: {},
      visible: false,
      formData: {},
      rules: {
        remark: [
          {required: true, message: '请输入申请备注', trigger: 'blur'}
        ],
      }
    };
  },
  mounted() {
  },
  destroyed() {
    this.formData = this.defaultData;
  },
  methods: {
    handleClose() {
      this.$refs["form"].resetFields();
      this.visible = false;
    },
    init(data) {
      this.formData = this.defaultData;
      this.formData.id = data.id;
      this.initList();
      this.visible = true;
    },
    resetQueryForm() {
      this.queryForm = {}
      return this;
    },
    queryDetail: function () {
      this.queryForm.user_reward_detail = {
        form: {
          id: this.formData.id,
        },
      }

      return this;
    },

    initList() {
      let that = this;
      this.queryDetail()
          .query()
          .then((response) => {
            if (response.code == 200) {
              var _data = response.data.user_reward_detail;
              that.project = _data.project;
              that.set = _data.directorSet;
              that.job = _data.job;
              that.reward = _data;
              that.userApp = _data.userApp;

              return true;
            }
            that.$message.error(response.msg ? response.msg : "请求错误");
          });
    },
    query() {
      return v2Query(this.queryForm);
    },
    submit(status) {
      var that = this;

      that.formData.status = status;
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return false;
        }
        v2Query({
          user_reward_audit: {
            form: that.formData,
          }
        }).then((response) => {
          if (response.code == 200) {
            this.$message({
              message: "提交成功",
              type: 'success'
            });
            that.$emit('refresh');
            that.visible = false;
          } else {
            this.$message({
              message: response.message ? response.message : "提交失败",
            });
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .myWindow {
  border-radius: 15px;

  .el-button--primary {
    color: #FFF;
    background-color: #717ebb;
    border-color: #717ebb;
  }

  /*
  .footer{
    display: flex;
    justify-content: center;
  }*/
}
</style>
